import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { window } from "browser-monads"

const ListContainer = tw.div`
container mx-auto flex flex-col
`

const List = tw.ul`
mx-auto
`

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const PodcastList = ({ heading, subHeading, ...props }) => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth)
    }, 1000)
    window.addEventListener("resize", debouncedHandleResize)
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })

  const data = useStaticQuery(graphql`
    query AllPodcasts {
      webinyHeadlessCms {
        listPodcasts {
          data {
            id
            title
            code
          }
        }
      }
    }
  `)

  const podcasts = data.webinyHeadlessCms.listPodcasts.data

  return (
    <ListContainer>
      <List>
        {podcasts.map(item => {
          return (
            <div>
              <iframe
                width={width > 1024 ? 1024 : width - 30}
                key={item.id}
                scrolling="no"
                data-name="pb-iframe-player"
                title={item.title}
                src={"https://www.podbean.com/media/player/" + item.code}
                allowfullscreen
              />
            </div>
          )
        })}
      </List>
    </ListContainer>
  )
}

export default PodcastList
