import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import SEO from "../layout/seo"
import Services from "../components/servicesPanel"
import TextPanel from "../components/textPanel"
import PodcastList from "../components/podcastList"
import Layout from "../layout"

const MainContainer = tw.div`
container mx-auto mt-12 md:mt-24
`

const PodcastPage = () => {
  const data = useStaticQuery(graphql`
    query HowMightWeHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "How Might We" }) {
          data {
            id
            heading
            subHeading
          }
        }
      }
    }
  `)

  const header = data.webinyHeadlessCms.getPageHeadings.data

  return (
    <>
      <SEO
        title="How Might We...?"
        description='"How Might We...?" is how we approach all opportunities, and it’s how we approach all challenges'
      />
      <Layout>
        <MainContainer>
          <Services heading={header.heading} subHeading={header.subHeading} />
          <TextPanel
            heading="These are all our podcasts"
            subHeading="They're SHAMAZING!"
          />
          <PodcastList />
        </MainContainer>
      </Layout>
    </>
  )
}

export default PodcastPage
