import React from "react"
import tw from "twin.macro"

const Container = tw.div`
flex flex-col mt-6 md:mt-0 prose prose-sm sm:prose lg:prose-lg xl:prose-xl
`

const ItemSummary = ({ ...props }) => {
  return (
    <li key={props.key}>
      <Container>
        <h3>{props.heading}</h3>
        <p>{props.subHeading}</p>
      </Container>
    </li>
  )
}

export default ItemSummary
