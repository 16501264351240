import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import TextPanel from "./textPanel"
import ItemSummary from "./serviceSummary"

const Container = tw.div`
px-3 max-w-screen-xl mx-auto mb-12 md:mb-24
`

const List = tw.ul`
mt-6 md:grid md:grid-cols-2 md:col-gap-4 md:row-gap-10
`

const Services = props => {
  const data = useStaticQuery(graphql`
    query allServices {
      webinyHeadlessCms {
        listServices {
          data {
            id
            heading
            subHeading
            icon
          }
        }
      }
    }
  `)
  const services = data.webinyHeadlessCms.listServices.data

  return (
    <Container>
      {props.heading ? (
        <TextPanel heading={props.heading} subHeading={props.subHeading} />
      ) : null}
      <List>
        {services.map(service => {
          return (
            <ItemSummary
              key={service.id}
              heading={service.heading}
              subHeading={service.subHeading}
            />
          )
        })}
      </List>
    </Container>
  )
}

export default Services
